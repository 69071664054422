import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../controller/Login/Login";
import paymentsReducer from "../controller/Payments/Payments";
import invoicesReducer from "../controller/Invoices/Invoices";
import usersReducer from "../controller/Users/Users";
import servicesReducer from "../controller/Services/Services";
import formsReducer from "../controller/Forms/Forms";
import registerReducer from "../controller/Register/Register";
import recipientsReducer from "../controller/Recipients/Recipients";
import panelReducer from "../controller/Panel/PanelData";

const rootReducer = combineReducers({
  auth: authReducer,
  payments: paymentsReducer,
  invoices: invoicesReducer,
  users: usersReducer,
  services: servicesReducer,
  forms: formsReducer,
  register: registerReducer,
  recipients: recipientsReducer,
  stats: panelReducer,
});

export default rootReducer;
