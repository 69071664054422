import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { axiosLogin, saveToken } from "../../utils/axiosRequest";

export const login = createAsyncThunk(
  "auth/login",
  async ({ body, navigate }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosLogin.post("login", body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      sessionStorage.setItem("isLogged", JSON.stringify(true));
      saveToken(data.access_token);
      navigate("/panel");
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const setError = (state, action) => {
  state.loading = false;
  state.authStatus = action.payload.error;
};

const setLoading = (state) => {
  state.loading = true;
  state.authStatus = null;
};

const slice = createSlice({
  name: "auth",
  initialState: {
    authStatus: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [login.pending]: setLoading,
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      state.loginStatus = "Login success";
    },
    [login.rejected]: setError,
  },
});

export default slice.reducer;
