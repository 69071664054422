import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import moment from "moment";
import { axiosRequest } from "../../utils/axiosRequest";

export const getServices = createAsyncThunk(
  "services/getServices",
  async (id, { rejectWithValue }) => {
    try {
      let uri = `services/`;
      if (id !== undefined) {
        uri += `?q=${id}`;
      }
      const { data } = await axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getServicesByIdOperators = createAsyncThunk(
  "services/getServicesByIdOperators",
  async (q, { rejectWithValue, getState }) => {
    const id = getState().services.id;

    try {
      let uri = `services/${id}/operators`;
      if (q !== undefined) {
        uri += `?q=${q}`;
      }
      const { data } = await axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const postServicesCreate = createAsyncThunk(
  "services/postServicesCreate",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.post(`services/`, body);

      dispatch(getServices());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const patchServicesUpdate = createAsyncThunk(
  "services/patchServicesUpdate",
  async (body, { rejectWithValue, dispatch, getState }) => {
    const id = getState().services.id;
    try {
      const { data } = await axiosRequest.patch(`services/${id}`, body);

      dispatch(getServices());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const patchServicesDisabled = createAsyncThunk(
  "services/patchServicesUpdateDisabled ",
  async (id, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await axiosRequest.patch(
        `services/disable?service_id=${id}`
      );

      dispatch(getServices());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getBanks = createAsyncThunk(
  "services/getBanks",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get("common/banks");

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getServicesPublic = createAsyncThunk(
  "services/getServicesPublic",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get(`services/public`);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getUnits = createAsyncThunk(
  "services/getUnits",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get("common/units");

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getCurrnecy = createAsyncThunk(
  "services/getCurrnecy",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get("common/currencies");

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const setError = (state, action) => {
  state.loading = false;
  state.authStatus = action.payload.error;
};

const setLoading = (state) => {
  state.loading = true;
};

const initialService = {
  name: "",
  description: "",
  price: null,
  currency_id: null,
  unit: "",
  unit_qty: null,
  city_id: "",
  is_fee: false,
};
const slice = createSlice({
  name: "services",
  initialState: {
    services: [],
    banks: [],
    servicesPublic: [],
    units: [],
    currencies: [],
    service: initialService,
    createModal: false,
    updateModal: false,
    deleteModal: false,
    loading: false,
    id: null,
  },
  reducers: {
    handleModalOpenAndClose: (state, action) => {
      const { name, value, id = null } = action.payload;
      state[name] = value;
      state.service = initialService;
      if (id) {
        state.id = id;
        let service = state.services.find((elem) => elem.id === id);
        service.start_date = moment(service.start_date);
        state.service = { ...service };
        state.service.name = service.name?.tj;
        state.service.description = service.description?.tj;
      }
    },
    handleChange: (state, action) => {
      const { name, value } = action.payload;
      state.service[name] = value;
    },
  },
  extraReducers: {
    [getServices.pending]: setLoading,
    [getServices.fulfilled]: (state, action) => {
      state.loading = false;
      state.services = action.payload.map((e) => {
        e.price /= 100;
        return e;
      });
    },
    [getServices.rejected]: setError,
    [getServicesPublic.pending]: setLoading,
    [getServicesPublic.fulfilled]: (state, action) => {
      state.loading = false;
      state.servicesPublic = action.payload.map((e) => {
        e.price /= 100;
        return e;
      });
    },
    [getServicesPublic.rejected]: setError,
    [getBanks.pending]: setLoading,
    [getBanks.fulfilled]: (state, action) => {
      state.loading = false;
      state.banks = action.payload;
    },
    [getBanks.rejected]: setError,
    [postServicesCreate.pending]: setLoading,
    [postServicesCreate.fulfilled]: (state, action) => {
      state.loading = false;
      state.createModal = false;
      notification["success"]({
        message: `${action?.payload?.name?.tj}`,
        description: "Новый cервис успешно создан!",
      });
    },
    [postServicesCreate.rejected]: setError,
    [patchServicesUpdate.pending]: setLoading,
    [patchServicesUpdate.fulfilled]: (state, action) => {
      state.loading = false;
      state.updateModal = false;

      notification["success"]({
        message: `${action?.payload?.name?.tj}`,
        description: "Сервис успешно изменен!",
      });
    },
    [patchServicesUpdate.rejected]: setError,
    [getUnits.pending]: setLoading,
    [getUnits.fulfilled]: (state, action) => {
      state.loading = false;
      state.units = action.payload;
    },
    [getUnits.rejected]: setError,
    [getCurrnecy.pending]: setLoading,
    [getCurrnecy.fulfilled]: (state, action) => {
      state.loading = false;
      state.currencies = action.payload;
    },
    [getCurrnecy.rejected]: setError,
  },
});

export const { handleModalOpenAndClose, handleChange } = slice.actions;

export default slice.reducer;
