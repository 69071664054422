import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../controller/Login/Login";
import View from "../../view/Login/Login";
import CryptoJS from "crypto-js";

function Login(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const loading = useSelector(({ auth }) => auth.loading);

  const onFinish = (e) => {
    e.preventDefault();

    var iv = CryptoJS.lib.WordArray.random(16);
    var key = CryptoJS.enc.Base64.parse("aR1h7EefwlPNVkvTHwfs6w==");

    var encrypted = CryptoJS.AES.encrypt(
      e.nativeEvent.srcElement[1].value,
      key,
      { iv: iv }
    );
    var joinedData = iv.clone().concat(encrypted.ciphertext);
    var joinedDataB64 = CryptoJS.enc.Base64.stringify(joinedData);

    const urlencoded = new URLSearchParams();
    urlencoded.append("username", e.nativeEvent.srcElement[0].value);
    urlencoded.append("password", joinedDataB64);
    dispatch(login({ body: urlencoded, navigate }));
  };

  return (
    <>
      <View onFinish={onFinish} loading={loading} />
    </>
  );
}

export default Login;
