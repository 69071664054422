import { Spin } from "antd";
import React from "react";
import "./Spinner.css";

function Spinner() {
  return (
    <div className="flex mt-10 justify-center">
      <Spin size="large" />
    </div>
  );
}

export default Spinner;
