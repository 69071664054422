import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import { axiosRequest } from "../../utils/axiosRequest";

export const getInvoices = createAsyncThunk(
  "payments/getInvoices",
  async ({ id, start, end } = {}, { rejectWithValue, dispatch }) => {
    try {
      let uri = `invoices/my`;
      if (id !== undefined) {
        uri += `?q=${id}`;
      } else if (start !== undefined) {
        uri += `?start_date=${start}`;
      } else if (end !== undefined) {
        uri += `?end_date=${end}`;
      }
      const { data } = await axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getInvoicesMyStatus = createAsyncThunk(
  "invoices/getInvoicesMyStatus",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.get("invoices/my/stats");

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const postInvoiceCreate = createAsyncThunk(
  "payments/postInvoiceCreate",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      let sendObj = { ...body };
      sendObj.create_date = new Date();
      sendObj.end_date = new Date();
      sendObj.currency = "tjs";

      const { data } = await axiosRequest.post(`invoices/`, sendObj);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const setError = (state, action) => {
  state.loading = false;
};

const setLoading = (state) => {
  state.loading = true;
};

const initialService = {
  description: "",
  count: 1,
  price: "",
};
const slice = createSlice({
  name: "payments",
  initialState: {
    invoices: [],
    total: {},
    service: initialService,
    serviceModal: false,
    invoiceModal: false,
    loading: false,
    invoice: "",
    id: null,
  },
  reducers: {
    changeId: (state, action) => {
      state.id = action.payload;
    },
    serviceModalOpenAndClose: (state, action) => {
      state.serviceModal = action.payload;
    },
    invoiceModalOpenAndClose: (state, action) => {
      state.invoiceModal = action.payload;
      state.service = initialService;
    },
    chooseServiceId: (state, action) => {
      state.id = action.payload.id;
      state.serviceModal = action.payload.serviceModal;
      state.invoiceModal = action.payload.invoiceModal;
    },
  },
  extraReducers: {
    [getInvoices.pending]: setLoading,
    [getInvoices.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload, "aaaa");
      state.invoices = action.payload;
    },
    [getInvoices.rejected]: setError,
    [getInvoicesMyStatus.pending]: setLoading,
    [getInvoicesMyStatus.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload, "11");
      state.total = action.payload;
    },
    [getInvoicesMyStatus.rejected]: setError,
    [postInvoiceCreate.pending]: setLoading,
    [postInvoiceCreate.fulfilled]: (state, action) => {
      state.loading = false;
      state.invoice = action.payload.invoice_number;
      state.invoiceModal = false;
      notification["success"]({
        message: `#${action.payload.invoice_number}`,
        description: "Счет (invoice) успешно создан!",
      });
    },
    [postInvoiceCreate.rejected]: setError,
  },
});

export const {
  changeId,
  serviceModalOpenAndClose,
  invoiceModalOpenAndClose,
  chooseServiceId,
} = slice.actions;

export default slice.reducer;
