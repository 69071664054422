import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { axiosRequest } from "../../utils/axiosRequest";

export const getPanelData = createAsyncThunk(
  "panel/getPanelData",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `invoices/total`;

      const { data } = await axiosRequest.get(uri);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const setError = (state, action) => {
  state.loading = false;
  state.authStatus = action.payload.error;
};

const setLoading = (state) => {
  state.loading = true;
};

const panelSlice = createSlice({
  name: "stats",
  initialState: {
    stats: {},
  },
  reducers: {},
  extraReducers: {
    [getPanelData.pending]: setLoading,
    [getPanelData.fulfilled]: (state, action) => {
      state.loading = false;
      state.stats = action.payload;
    },
    [getPanelData.rejected]: setError,
  },
});

export default panelSlice.reducer;
